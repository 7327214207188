import { gql } from "@apollo/client";

const POST_SIGNUP = gql`
  mutation Signup(
    $email: String!
    $password: String!
    $interests: [ID!]
    $signUpCode: String
    $referralCode: String
    $integrationPayload: SignUpIntegrationPayload
    $info: SignUpProfileInput!
    $source: String
    $fingerprint: String!
    $recaptchaToken: String!
    $postSignupActions: [SignupPostSignupActions] = []
  ) {
    signup(
      email: $email
      password: $password
      interests: $interests
      signUpCode: $signUpCode
      referralCode: $referralCode
      integrationPayload: $integrationPayload
      info: $info
      source: $source
      fingerprint: $fingerprint
      recaptchaToken: $recaptchaToken
      postSignupActions: $postSignupActions
    ) {
      tokens {
        accessToken
      }
    }
  }
`;

export default POST_SIGNUP;
