import Divider from "@mui/material/Divider";
import { Box, Button, Image, Link, Logo, Stack } from "components";
import { APP } from "consts";
import { useConsole } from "contexts";
import { useProvider } from "hooks/session";
import InstallButton from "layouts/app/components/user/InstallButton";
import { usePathname, useRouter } from "next/navigation";
import { openUrl } from "utils";

function Topbar({ config = {} }) {
  const { screen } = useConsole();
  const { provider } = useProvider();
  const { push } = useRouter();

  const pathname = usePathname();
  const register = pathname.includes("signup");

  return (
    <Box component="nav" className="BupBrowser-nav">
      <Stack alignItems="center" flexDirection="row" spacing={2} useFlexGap>
        <Link onClick={() => openUrl(APP.websiteUrl, 0, "_self")}>
          <Logo className="BupBrowser-logo" size="large" />
          <span className="BupLogo-span">{APP.alias}</span>
        </Link>
        {provider && (
          <>
            <Divider orientation="vertical" flexItem />
            <Image
              alt={provider.id}
              className="BupLogo-provider"
              layout="responsive"
              src={`partners/${provider.src}`}
              height={25}
              width={100}
            />
          </>
        )}
      </Stack>
      <Stack
        alignItems="center"
        flexDirection="row"
        justifyContent="flex-end"
        spacing={1}
        useFlexGap
      >
        <InstallButton id="BT-install" hidden={!screen.tablet} register />
        <Button
          id="BT-login"
          className={screen.tablet ? "BupButton-dark" : "BupButton-inherit"}
          color="inherit"
          hidden={!register && screen.tablet}
          onClick={() => push("/")}
        >
          Iniciar sesión
        </Button>
        <Button
          id="BT-signup"
          className="BupButton-dark"
          color="primary"
          onClick={() =>
            push({ pathname: "/signup", query: { source: "WEB" } })
          }
          hidden={(register && screen.tablet) || config.hideRegister}
          size="large"
        >
          Regístrate
        </Button>
      </Stack>
    </Box>
  );
}

export default Topbar;
